.edit-design-steps-col {
    width: 100%;
    max-width: 250px;
    border-style: solid;
    border-width: 0px;
    border-right-width: 2px;
    border-right-color: #e6e6e6;
}
.edit-design-step {
    width: 100%;
    display: flex;
    border-width: 0px;
    align-items: center;
    padding-left: 5px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
    border-bottom-color: #e6e6e6;
    padding: 15px 0px;
}
.edit-design-step-number {
    background-color: #0073e6;
    color: white;
    font-size: 12px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-design-step-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}
.edit-design-step-description {
    font-size: 12px;
    margin-bottom: 0px;
}
.edit-designs-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    min-height: calc(100vh - 300px);
}
.back-to-designs-wrapper {
    padding: 15px 0px;
}
.edit-designs-content {
    padding: 0px 20px;
    padding-top: 0px;
    width: calc(100% - 250px);
}
.upload-poster-header-row {
    padding-bottom: 20px;
}
.upload-poster-header-row h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
}
.upload-poster-header-row p {
    font-size: 14px;
    margin-bottom: 0px;
}
.edit-design-form-inner-row {
    min-height: calc(100vh - 400px);
    width: 100%;
}
.next-btns-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
}
.edit-design-file-uploaded-row {
    margin-top: 10px;
    color: #088A08;
}
.edit-design-file-uploading-row {
    margin-top: 10px;
    color: #0073e6;
    display: flex;
}
.edit-design-file-uploading-row .spinner-border {
    margin-right: 5px;
    height: 20px;
    width: 20px;
    border-width: 2px;
}
.edit-design-file-uploading-row p {
    font-size: 14px;
    font-weight: 500;
}
.edit-design-step-number-disabled {
    background-color: #e6e6e6;
    color: #b3b3b3;
}
.edit-design-loading-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
    flex-direction: column;
}
.edit-design-loading-row p {
    margin-top: 15px;
    font-size: 14px;
}
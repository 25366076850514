.app-store-cover-placeholder {
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
}
.app-title-row {
  padding-top: 20px;
  padding-bottom: 30px;
}
.app-title-row h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2px;
}
.app-store-title-subtext {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.search-col {
  align-items: center;
}
.search-input-box {
  width: 100%;
  max-width: 300px;
  margin-bottom: 25px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  margin-right: 0;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.search-input-box .bi {
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  width: 17px;
  color: #d8d8d8;
}
.search-input-box input {
  display: inline-block;
  border: none;
  width: calc(100% - 32px);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

/* App Store Listing */
/*
.app-store-listing-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  display: flex;

  flex-direction: column;
}
.app-store-listing-item .cover-section {
  width: 100%;
  height: 90px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.app-store-listing-item .icon-row {
  margin-top: -40px;
}
.app-store-listing-item .icon-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-left: auto;
  margin-right: auto;
}
.app-store-listing-item .icon-wrapper .icon {
  height: 100%;
  border-radius: 50%;
}
.app-store-listing-item .description {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-height: 100px;
  max-height: 100px;
}
.actions-row {
  padding: 10px;
  padding-bottom: 20px;
  text-align: center;
}
.actions-row .install-btn .spinner-border {
  width: 18px;
  height: 18px;
  border-width: 1px;
  margin: 0px 20px;
}
.actions-row .learn-more-link {
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}
.actions-row .learn-more-link i {
  margin-right: 5px;
}
*/
.app-listing-details-col {
  display: flex;
}
.icon-wrapper {
  width: 80px;
  height: 80px;
}
.icon-wrapper .icon {
  height: 100%;
}
.app-details-inner {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-details-inner .app-name {
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
}
.app-details-inner .app-description {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}